/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createContext,
  useContext,
  useEffect,
  type MutableRefObject,
  type ReactNode,
} from "react";
import { createPortal } from "react-dom";

import { cssFns } from "@superweb/css";

import { useUiColors, useUiShadows } from "./theme";
import { useIsMobile } from "./mobile-context";
import { FloatingPanelContext } from "./floating";

const BottomSheetContext =
  createContext<MutableRefObject<HTMLDivElement | null> | null>(null);

export const BottomSheetContextProvider = BottomSheetContext.Provider;

export const useBottomSheetContext = () => useContext(BottomSheetContext);

/**
 * Bottom sheet for actions and information.
 *
 * ATTENTION: This component works only with new layout.
 * {@link https://a.yandex-team.ru/arc_vcs/taxi/superweb/@superweb/ui/src/layouts/app-layout.tsx#L75 | PageLayout}
 *
 * If your product has guidance, don't forget to add path to required page here:
 * {@link https://a.yandex-team.ru/arc_vcs/taxi/superweb/@apps/yandex-fleet/src/guidance/panel.tsx#L99}
 * Otherwise, elements will overlap each other
 *
 * Recommended children buttons sizes: 's' and 'xs'
 */
export const BottomSheet = ({
  children,
  size = "s",
}: {
  children: ReactNode;
  size?: "s" | "m";
}) => {
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const { setOffset: setFloatingOffset } = useContext(FloatingPanelContext);

  const isMobile = useIsMobile();

  const ref = useBottomSheetContext();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setFloatingOffset({ block: ref.current.clientHeight });
    }
    return () => setFloatingOffset({ block: 0 });
  }, [ref, setFloatingOffset]);

  return ref?.current
    ? createPortal(
        <div
          css={{
            boxShadow: uiShadows.topNormal,
            backgroundColor: uiColors.background,
            borderTopLeftRadius: "26px",
            borderTopRightRadius: "26px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            ...cssFns.padding("16px"),
            height: { s: "40px", m: "56px" }[size],
            ...(!isMobile && {
              ...cssFns.margin("0", "16px"),
            }),
          }}
        >
          {children}
        </div>,
        ref.current,
      )
    : null;
};
