import { MessageCache, createMessages } from "@superweb/intl";

export const { Messages, Message, useMessage } = createMessages({
  cache: new MessageCache({
    key: ({ baseName }: Intl.Locale) => baseName,
    load: (locale: string) => {
      return importTranslations(locale)?.then((module) => module.default);
    },
  }),
  sourceLanguage: "en",
});

const importTranslations = (locale: string) => {
  switch (locale) {
    case "am":
      return import("./translations/am.json");
    case "ar":
      return import("./translations/ar.json");
    case "az-Latn":
      return import("./translations/az-Latn.json");
    case "es-419":
      return import("./translations/es-419.json");
    case "fi":
      return import("./translations/fi.json");
    case "fr":
      return import("./translations/fr.json");
    case "he":
      return import("./translations/he.json");
    case "ka":
      return import("./translations/ka.json");
    case "pt-PT":
      return import("./translations/pt-PT.json");
    case "ro":
      return import("./translations/ro.json");
    case "ru":
      return import("./translations/ru.json");
    case "sr-Latn":
      return import("./translations/sr-Latn.json");
    case "tr":
      return import("./translations/tr.json");
    case "ur":
      return import("./translations/ur.json");
    case "hy":
      return import("./translations/hy.json");
    case "kk":
      return import("./translations/kk.json");
    case "uz":
      return import("./translations/uz.json");
    default:
      return undefined;
  }
};
