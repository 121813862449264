/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode, PointerEvent } from "react";
import { useHover } from "react-aria";
import { cssFns } from "@superweb/css";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import type {
  MenuGroup,
  SubMenuGroup as SubMenuGroupItem,
  SubMenuLink as SubMenuLinkItem,
} from "./menu";
import type { NavMenuState } from "./menu-state";
import { isValidSubGroup } from "./group-validator";

export const SubMenu = ({
  state,
  group,
}: {
  state: NavMenuState;
  group: MenuGroup;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  return (
    <Popover state={state}>
      <div
        css={{
          height: "100%",
          ...cssFns.padding("12px"),
          paddingInlineStart: "8px",
          boxSizing: "border-box",
          overflowY: "auto",
        }}
        onMouseEnter={() => {
          state.hoverOnSubMenu = true;
        }}
      >
        {group.label && (
          <div
            css={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              marginBlockEnd: "16px",
              ...cssFns.padding("0", "8px"),
            }}
          >
            <h3
              css={{
                ...cssFns.margin("0"),
                ...cssFns.padding("0"),
                ...typo({
                  level: "title3",
                  weight: "bold",
                  density: "tight",
                }),
                color: uiColors.text,
                ...cssFns.overflow("hidden"),
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                lineHeight: "none",
              }}
            >
              {group.label}
            </h3>
          </div>
        )}
        {group.items.map((item, index) => (
          <SubMenuGroup key={index} state={state} item={item} />
        ))}
      </div>
    </Popover>
  );
};

const Popover = ({
  state,
  children,
}: {
  state: NavMenuState;
  children: ReactNode;
}) => {
  const uiColors = useUiColors();

  const closeOnHover = () => {
    if (state.submenuTriggerType === "click") return;

    state.closeSubMenu();
  };

  return (
    <>
      <div
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            state.closeSubMenu();
          }
        }}
        onMouseEnter={closeOnHover}
        css={{
          position: "fixed",
          insetInlineStart: "88px",
          insetInlineEnd: "0",
          top: "0",
          bottom: "0",
          backgroundColor: uiColors.fog,
        }}
      />

      <div
        css={{
          width: "276px",
          position: "absolute",
          insetInlineStart: "100%",
          top: "0",
          bottom: "0",
          borderEndEndRadius: "24px",
          borderStartEndRadius: "24px",
          backgroundColor: uiColors.backgroundMinor,
          zIndex: "1",
        }}
      >
        {children}
      </div>
    </>
  );
};

const SubMenuGroup = ({
  state,
  item,
}: {
  state: NavMenuState;
  item: SubMenuGroupItem | undefined | null | false;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  if (!item || !isValidSubGroup(item) || item.items.length === 0) return <></>;

  return (
    <div css={{ marginBlockEnd: "20px" }}>
      {item.label && (
        <h4
          css={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            ...cssFns.margin("0"),
            ...cssFns.padding("0", "8px"),
            ...typo({ level: "body2", weight: "medium", density: "tight" }),
            color: uiColors.text,
          }}
        >
          {item.label}
        </h4>
      )}

      <ul
        css={{
          display: "flex",
          flexDirection: "column",
          ...cssFns.margin("0"),
          ...cssFns.padding("0"),
          ...cssFns.gap("2px"),
          listStyleType: "none",
        }}
      >
        {item.items.map(
          (item, index) =>
            item && <SubMenuLink key={index} state={state} item={item} />,
        )}
      </ul>
    </div>
  );
};

const SubMenuLink = ({
  state,
  item,
}: {
  state: NavMenuState;
  item: SubMenuLinkItem;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  const { isHovered, hoverProps } = useHover({});

  return (
    <li css={{ width: "100%" }}>
      <a
        {...hoverProps}
        id={item.id}
        href={item.link.href}
        onClick={(e: PointerEvent<HTMLAnchorElement>) => {
          item.link.onClick?.(e);
          state.closeSubMenu();
        }}
        target={item.link.target}
        css={{
          display: "flex",
          alignItems: "center",
          ...cssFns.border({ width: "0", radius: "10px" }),
          ...cssFns.padding("8px"),
          boxSizing: "border-box",
          ...typo({ level: "body2", weight: "regular", density: "tight" }),
          textDecorationStyle: "none",
          cursor: "pointer",
          color: item.link.isActive ? uiColors.text : uiColors.textMinor,
          backgroundColor:
            item.link.isActive || isHovered ? uiColors.hover : "transparent",
          textDecorationLine: "none",
        }}
      >
        {item.label}
      </a>
    </li>
  );
};
