/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useKeyframes } from "@superweb/css";
import { useUiColors } from "./theme";

export const Spin = ({ size = "m" }: { size?: "xs" | "s" | "m" | "l" }) => {
  const keyframes = useKeyframes();
  const uiColors = useUiColors();

  const a = { xs: 32, s: 40, m: 48, l: 56 }[size];
  const s = 4 / a;
  const r = 0.5 - s / 2;
  const cx = 0.5;
  const cy = 0.5;
  const C = 2 * Math.PI * r;

  return (
    <i css={{ display: "inline-flex" }} role="progressbar">
      <svg
        viewBox="0 0 1 1"
        css={{
          width: `${a}px`,
          height: `${a}px`,

          animationDuration: "2s",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          animationName: keyframes({
            0: {
              transform: "rotateZ(0deg)",
            },
            100: {
              transform: "rotateZ(360deg)",
            },
          }),
        }}
      >
        <circle
          css={{
            fill: "none",
            r: `${r}px`,
            cx: `${cx}px`,
            cy: `${cy}px`,
            stroke: uiColors.control,
            strokeWidth: `${s}px`,
            strokeLinecap: "round",
            strokeDasharray: `${C}px`,
            transformOrigin: "50% 50%",

            animationDuration: "1.4s",
            animationTimingFunction: "ease-in-out",
            animationIterationCount: "infinite",
            animationFillMode: "both",
            animationName: keyframes({
              0: {
                transform: "rotateZ(0deg)",
                strokeDashoffset: `${C - C / 360}`,
              },
              50: {
                transform: "rotateZ(45deg)",
                strokeDashoffset: `${C - (C / 360) * 270}`,
              },
              100: {
                transform: "rotateZ(360deg)",
                strokeDashoffset: `${C - C / 360}`,
              },
            }),
          }}
        />
      </svg>
    </i>
  );
};
