/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns, useCss } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  type ReactElement,
} from "react";
import { useBrandColors, useUiColors } from "./theme";
import { DialogContextProvider } from "./dialog";
import { SnackbarContextProvider } from "./snackbar";
import { useDebouncedState } from "./state";
import { PopoverContextProvider } from "./popover";
import {
  FloatingPanelContextProvider,
  FloatingDialogContextProvider,
} from "./floating";
import { GuidanceOverlayContextProvider } from "./experimental-guidance-overlay";

export const AppContainer = ({ children }: { children: ReactElement }) => {
  const uiColors = useUiColors();
  const brandColors = useBrandColors();
  const locale = useLocale();

  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const snackbarContainerRef = useRef<HTMLDivElement>(null);
  const dialogContainerRef = useRef<HTMLDivElement>(null);
  const floatingContainerRef = useRef<HTMLDivElement>(null);
  const floatingPanelContainerRef = useRef<HTMLDivElement>(null);
  const floatingDialogContainerRef = useRef<HTMLDivElement>(null);
  const guidanceOverlayContainerRef = useRef<HTMLDivElement>(null);
  const [floatingOffsetInline, setFloatingOffsetInline] = useState(0);
  const [floatingOffsetBlock, setFloatingOffsetBlock] = useState(0);
  const [dialogStack, setDialogStack] = useState<unknown[]>([]);
  const [floatingDialogInstance, setFloatingDialogInstance] =
    useState<unknown>();
  const [popoverContainer, setPopoverContainer] =
    useState<HTMLDivElement | null>(null);

  const isDialogOpen =
    dialogStack.length > 0 || Boolean(floatingDialogInstance);
  const debouncedDialogOpen = useDebouncedState(isDialogOpen, 0);

  const bodyClassName = useCss({
    color: brandColors.text,
    backgroundColor: brandColors.background,
    ...cssFns.margin("0"),
  });

  useLayoutEffect(() => {
    const list = bodyClassName.split(" ");
    document.body.classList.add(...list);
    return () => {
      document.body.classList.remove(...list);
    };
  }, [bodyClassName]);

  useLayoutEffect(() => {
    document.body.dir = locale.textInfo.direction;
  }, [locale.textInfo.direction]);

  useLayoutEffect(() => {
    const floatingContainer = floatingContainerRef.current;
    const dialogContainer =
      dialogContainerRef.current || floatingDialogContainerRef.current;
    if (isDialogOpen && floatingContainer && dialogContainer) {
      const { clientWidth, clientHeight } = document.documentElement;
      if (floatingOffsetInline === 0) {
        const vScrollWidth = window.innerWidth - clientWidth;
        floatingContainer.style.paddingInlineEnd = `${vScrollWidth}px`;
      }
      if (floatingOffsetBlock === 0) {
        const hScrollWidth = window.innerHeight - clientHeight;
        floatingContainer.style.paddingBlockEnd = `${hScrollWidth}px`;
      }
      return () => {
        floatingContainer.removeAttribute("style");
      };
    } else {
      return;
    }
  }, [isDialogOpen, floatingOffsetBlock, floatingOffsetInline]);

  useLayoutEffect(() => {
    const childrenContainer = childrenContainerRef.current;
    const dialogContainer =
      dialogContainerRef.current || floatingDialogContainerRef.current;
    if (isDialogOpen && childrenContainer && dialogContainer) {
      const { scrollTop, scrollLeft, clientWidth } = document.documentElement;
      const scrollWidth = window.innerWidth - clientWidth;
      childrenContainer.style.width = `calc(100vw - ${scrollWidth}px`;
      childrenContainer.style.height = "100vh";
      childrenContainer.style.overflow = "hidden";
      childrenContainer.style.position = "fixed";
      childrenContainer.style.top = "0px";
      childrenContainer.style.left = "0px";
      childrenContainer.style.paddingRight = `${scrollWidth}px`;
      childrenContainer.scrollTop = scrollTop;
      childrenContainer.scrollLeft = scrollLeft;
      return () => {
        childrenContainer.removeAttribute("style");
        document.documentElement.scrollTop = scrollTop;
        document.documentElement.scrollLeft = scrollLeft;
      };
    } else {
      return;
    }
  }, [isDialogOpen]);

  const handleSetFloatingOffset = useCallback(
    ({ block, inline }: { block?: number; inline?: number }) => {
      setFloatingOffsetInline((value) => inline ?? value);
      setFloatingOffsetBlock((value) => block ?? value);
    },
    [],
  );

  return (
    <SnackbarContextProvider value={snackbarContainerRef}>
      <DialogContextProvider
        value={{
          containerRef: dialogContainerRef,
          stack: dialogStack,
          onStackChange: setDialogStack,
        }}
      >
        <PopoverContextProvider
          value={{
            container: popoverContainer,
          }}
        >
          <FloatingDialogContextProvider
            value={{
              containerRef: floatingDialogContainerRef,
              dialogInstance: floatingDialogInstance,
              onDialogInstanceChange: setFloatingDialogInstance,
            }}
          >
            <FloatingPanelContextProvider
              value={{
                containerRef: floatingPanelContainerRef,
                setOffset: handleSetFloatingOffset,
              }}
            >
              <GuidanceOverlayContextProvider
                value={{ containerRef: guidanceOverlayContainerRef }}
              >
                <div
                  css={{
                    color: uiColors.text,
                    height: "100%",
                  }}
                >
                  <div
                    ref={childrenContainerRef}
                    {...(isDialogOpen && {
                      inert: "",
                      "aria-hidden": "true",
                    })}
                    css={{
                      isolation: "isolate",
                      height: "100%",
                      boxSizing: "border-box",
                      // Workaround for https://github.com/adobe/react-spectrum/issues/1513
                      // Сorrects the triggering of clicks in child content
                      // when a dialog box is open
                      pointerEvents:
                        isDialogOpen || debouncedDialogOpen
                          ? "none"
                          : undefined,
                    }}
                  >
                    {children}
                  </div>
                  <div
                    ref={floatingContainerRef}
                    css={{
                      position: "fixed",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "8px",
                      insetInlineEnd: `${floatingOffsetInline + 16}px`,
                      insetBlockEnd: `${floatingOffsetBlock + 16}px`,
                    }}
                  >
                    <div ref={floatingDialogContainerRef} />
                    <div ref={floatingPanelContainerRef} />
                  </div>
                  <div ref={(element) => setPopoverContainer(element)} />
                  <div ref={dialogContainerRef} />
                  <div
                    ref={snackbarContainerRef}
                    css={{
                      position: "fixed",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                      display: "flex",
                      flexDirection: "column-reverse",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      pointerEvents: "none",
                      ...cssFns.padding("16px"),
                    }}
                  />
                </div>
                <div ref={guidanceOverlayContainerRef} />
              </GuidanceOverlayContextProvider>
            </FloatingPanelContextProvider>
          </FloatingDialogContextProvider>
        </PopoverContextProvider>
      </DialogContextProvider>
    </SnackbarContextProvider>
  );
};
